import React, { ReactNode } from 'react';

interface Layout2x1ImageRightProps {
  image?: ReactNode;
  hero?: boolean;
  rowClassName?: string;
  contentClassName?: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & Layout2x1ImageRightProps>;

const Layout2x1ImageRight: Props = ({
  className = '',
  children,
  image,
  hero = false,
  rowClassName = '',
  contentClassName = '',
  ...props
}) => {
  return (
    <div {...props} className={`container  ${className}`}>
      <div className={`row ${rowClassName}`}>
        {hero ? (
          <>
            <div className="col-span-12 xs:col-span-5 md:col-start-2 md:col-end-6 ">
              <div className={`space-y-16 ${contentClassName}`}>{children}</div>
            </div>
            {image && (
              <div className="col-span-12 row-start-1 mb-32 xs:col-span-7 xs:row-start-auto xs:mb-0 md:col-start-7 md:col-end-13">
                {image}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="col-span-12 col-start-3 col-end-13 xs:col-start-2 xs:col-end-7 md:col-start-3 md:col-end-7 lg:col-end-7">
              <div className={`space-y-16 ${contentClassName}`}>{children}</div>
            </div>
            {image && (
              <div className="col-span-12 col-start-1 row-start-1 mb-32 xs:col-start-7 xs:col-end-13 xs:row-start-auto xs:mb-0 md:col-start-8 md:col-end-13 lg:col-end-12">
                <div className="flex justify-center items-center">
                  {image}
                </div>
              </div>
            )}
            <div className="hidden lg:col-span-1 lg:block" />
          </>
        )}
      </div>
    </div>
  );
};

export default Layout2x1ImageRight;
