import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Layout2x1ImageLeftProps {
  image?: ReactNode;
  hero?: boolean;
  rowClassName?: string;
  contentClassName?: string;
  fullSplit?: boolean;
}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & Layout2x1ImageLeftProps>;

const Layout2x1ImageLeft: Props = ({
  className = '',
  children,
  image,
  hero = false,
  rowClassName = '',
  contentClassName = '',
  fullSplit = false,
  ...props
}) => {
  return (
    <div {...props} className={twMerge(!fullSplit && 'container', className)}>
      <div className={`row ${rowClassName}`}>
        {hero ? (
          <>
            {image && <div className="col-span-12 mb-32 xs:col-span-7 xs:mb-0 md:col-span-6">{image}</div>}
            <div
              className={`col-span-12 xs:col-span-5 ${fullSplit ? 'md:col-start-7' : 'md:col-start-8'} md:col-end-12`}
            >
              <div className={`space-y-16 ${contentClassName}`}>{children}</div>
            </div>
          </>
        ) : (
          <>
            {image && (
              <div
                className="
                col-span-12 
                mb-32 
                xs:col-span-6 xs:mb-0 
                sm:col-start-1 sm:col-end-7 md:col-start-1 md:col-end-6
                lg:col-start-2
              "
              >
                <div className="flex items-center justify-center">{image}</div>
              </div>
            )}
            <div
              className="
                col-start-3 col-end-13 
                xs:col-start-8 xs:col-end-13 
                sm:col-start-8 sm:col-end-13 
                md:col-start-7 md:col-end-11
              "
            >
              <div className={twMerge('space-y-16', contentClassName)}>{children}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Layout2x1ImageLeft;
