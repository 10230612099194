import React from 'react';

interface IconStarProps {
  level?: number;
  foreground?: string;
  background?: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconStarProps>;

const ignoreUpdatedProps = () => true;

const IconStar: Props = React.memo(
  ({
    id = `${Date.now()}${Math.random()}`,
    className,
    foreground = 'text-black',
    background = 'text-gray',
    level = 1,
    ...props
  }) => (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 ${className ? className : ''}`}
      fill="none"
    >
      <title>Star</title>

      <path
        d="M8 1l1.572 4.837h5.085l-4.114 2.99 1.572 4.836L8 10.673l-4.114 2.99 1.571-4.837-4.114-2.99h5.085L8 1z"
        className={`fill-current ${background ? background : ''}`}
      />

      <mask id={`starMask${id}`} mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <path
          d="M8 1l1.572 4.837h5.085l-4.114 2.99 1.572 4.836L8 10.673l-4.114 2.99 1.571-4.837-4.114-2.99h5.085L8 1z"
          fill="#000000"
        />
      </mask>

      <g mask={`url(#starMask${id})`}>
        <rect
          width="16"
          height="16"
          style={{ transform: `scaleX(${level})` }}
          className={`fill-current ${foreground ? foreground : ''}`}
        />
      </g>
    </svg>
  ),
  ignoreUpdatedProps
);

export default IconStar;
