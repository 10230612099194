import { Children, cloneElement, isValidElement, useState } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';

import IconArrowRight from './IconArrowRight';
import IconArrowLeft from './IconArrowLeft';

type CarouselProps = PropsWithChildren<DataTestIdProp & ClassNameProp>;

const Carousel = ({ className, children, ...props }: CarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const childrenArr = Children.toArray(children);
  const slideCount = childrenArr.length;

  return (
    <div
      {...props}
      className={`carousel polaroid relative w-full max-w-full overflow-hidden shadow-3 ${className ? className : ''}`}
    >
      <div className="relative overflow-hidden">
        <div
          className="grid transition duration-500 ease-in-out"
          style={{
            width: `${slideCount * 100}%`,
            transform: `translateX(${(100 / slideCount) * -(currentSlide - 1)}%)`,
            gridTemplateColumns: `repeat(${slideCount}, 1fr)`,
          }}
        >
          {slideCount > 0 &&
            childrenArr.map((child, i) => (
              <div key={i} className="aspect-w-5 aspect-h-7">
                {isValidElement(child) &&
                  cloneElement(child as ReactElement<HTMLDivElement>, {
                    className: 'absolute w-full h-full object-cover',
                  })}
              </div>
            ))}
        </div>
      </div>

      <div className="absolute bottom-0 right-0 flex space-x-1">
        <button
          className="btn-info btn btn-sm"
          disabled={currentSlide === 1}
          onClick={() => setCurrentSlide(currentSlide - 1)}
        >
          <IconArrowLeft />
        </button>

        <button
          className="btn-info btn btn-sm"
          disabled={currentSlide === slideCount}
          onClick={() => setCurrentSlide(currentSlide + 1)}
        >
          <IconArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
