import React from 'react';
import IconStar from './IconStar';

interface StarRatingProps {
  rating: number;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & StarRatingProps>;

const StarRating: Props = ({ className, rating, ...props }) => {
  let chunked: number[] = chunkRating(rating);
  let filled: number = chunked[0];
  let remainder: number = chunked[1];
  let empty: number = chunked[2];

  return (
    <div {...props} title={`${rating} out of 5 stars`} className={`flex space-x-4 ${className ? className : ''}`}>
      {Array.from({ length: filled }).map((item, index) => (
        <IconStar key={`filled ${index}`} level={1} foreground={'text-gold-darker'} />
      ))}
      {remainder > 0 && <IconStar level={remainder} foreground={'text-gold-darker'} />}
      {Array.from({ length: empty }).map((item, index) => (
        <IconStar key={`empty ${index}`} level={0} foreground={'text-gold-darker'} />
      ))}
    </div>
  );
};

export default StarRating;

const chunkRating = (rating: number): number[] => {
  let maxRating: number = 5;
  let clippedRating = rating > maxRating ? maxRating : rating;

  let filled: number = Number(Math.floor(clippedRating).toFixed(0));
  let part: number = Number((clippedRating % 1).toFixed(1));

  let emptyPart: number = part > 0 ? 1 : 0;
  let empty: number = maxRating - filled - emptyPart;

  return [filled, part, empty];
};
